<template>
  <v-container fluid pa-0>
    <v-app-bar color="" app fixed clippedLeft v-if="hideMenu">
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-list dense class="pa-0 align-self-center">
              <v-list-item class="px-2" v-bind="attrs" v-on="on">
                <v-list-item-avatar
                  color="#839299"
                  size="40"
                  class="mr-2 white--text"
                >
                  <span class="text-h5">{{
                    userName[0] != null && userName[0] != undefined
                      ? userName[0].toUpperCase()
                      : ""
                  }}</span>
                </v-list-item-avatar>

                <v-list-item-title>
                  <span class="font-weight-bold text-overline">{{
                    userName
                  }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
          <v-list>
            <v-list-item>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    block
                    color="primary"
                    class="mr-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="logout"
                    ><v-icon small left>logout</v-icon>Logout</v-btn
                  >
                </template>
                <span>Logout</span>
              </v-tooltip>
            </v-list-item>
            <v-list-item>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    block
                    color="primary"
                    class="mr-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="resetPassword"
                    ><v-icon small left>password</v-icon>Reset Password</v-btn
                  >
                </template>
                <span>Reset Password</span>
              </v-tooltip>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <keep-alive>
      <v-container fluid pa-0 v-if="!homePageLoading">
        <v-row no-gutters class="app-steps">
          <v-col cols="12">
            <v-breadcrumbs :items="appSteps">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
              <template v-slot:divider>
                <v-icon>arrow_forward_ios</v-icon>
              </template>
            </v-breadcrumbs>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else style="height: 80vh" fluid pa-0>
      <v-row style="height:100%" align-content="center" justify="center">
        <v-col cols="12" class="text-subtitle-1 text-center">
          <v-progress-circular color="primary" indeterminate rounded :size="50"></v-progress-circular>
          <p> Please wait...</p>
        </v-col>
      </v-row>
    </v-container>
    </keep-alive>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NavView",
  components: {},
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      userName: "authStore/getterUserName",
      ltype: "authStore/getterUserLType",
      appSteps:"pinaStore/getterAppSteps"
    }),
    hideMenu() {
      return (
        this.$route.name !== "LoginView" &&
        this.$route.name !== "ForgotPasswordView"
      );
    },
    homePageLoading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"]("ZONES");
    },
  },
  watch: {},
  async mounted() {},
  methods: {
    logout() {
      this.$store.commit("authStore/SET_USER", {});
      this.$store.commit("authStore/SET_DIVRT_TOKEN", "");
      this.$store.commit("pinaStore/RESET_STATE");
      this.$store.commit("pinaStore/RESET_APP_STEPS");
      localStorage.removeItem("vuex");
      localStorage.setItem("divrtToken", "");
      this.$router.replace({ path: "login" });
    },
    resetPassword() {
      this.$router.push({ path: "/resetpassword" });
    },
  },
};
</script>
<style scoped>
.app-steps{
  position:sticky;
  top:56px;
  background:white;
  z-index: 4;
}
</style>
